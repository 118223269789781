import { Button } from "../../../../components/ui/Button";
import { Stars } from "../../../../components/ui/modals/rating-modal/Stars";
import { capitalizeText } from "../../../../utils/capitalizeText";
import {
  BriefCaseIcon,
  BuildingIcon,
  CalenderIcon,
  LocationIcon,
  RupeeIcon,
  LinkedInIcon
} from "../../../../icons/icons";
import FavIcon from "../../../../images/favicon.png";

const MentorDetails = ({ details, handleConfirmationModal }) => {
  return (
    <div className="grid grid-cols-7 gap-2">
      {details?.deactivate === "1" && details?.deactive_reason?.length > 0 && (
        <div className="col-span-7 md:hidden bg-slate-200 shadow-md rounded-md py-2 px-3">
          <h5 className="font-semibold pb-2 text-secondary">
            Deactivation Reason :
          </h5>
          <p className="font-medium">{details?.deactive_reason}</p>
        </div>
      )}
      <div className="col-span-7 bg-white shadow-md p-3 rounded-md md:col-span-3">
        <div className="h-[90px] w-[90px] lg:h-[140px] lg:w-[140px] flex items-center border rounded-full">
          <img
            src={details?.logo?.length < 1 ? FavIcon : details?.logo}
            className="max-h-[90px] lg:max-h-[140px] p-1 mx-auto rounded-full"
            alt="profile-img"
          />
          <div className="flex flex-col justify-center items-end md:hidden">
            {details?.is_suspend === "0" ? (
              <Button
                className="md:hidden px-2 py-1 mx-1 text-xs"
                bgColor="bg-secondary"
                onClick={() => handleConfirmationModal("suspend")}
              >
                Suspend
              </Button>
            ) : (
              <Button
                className="md:hidden px-2 py-1 mx-1 text-xs"
                bgColor="bg-green-500"
                onClick={() => handleConfirmationModal("unsuspend")}
              >
                Un-Suspend
              </Button>
            )}
            {details?.platform_verify === "0" ? (
              <Button
                className="px-2 py-1 text-xs"
                id="verify-btn"
                onClick={() => handleConfirmationModal("verify")}
              >
                Verify
              </Button>
            ) : (
              <Button className="px-2 py-1 text-xs" id="verified-btn" disabled>
                Verified
              </Button>
            )}
            <div className="my-3">
              <Stars rating={details?.details?.average_rating} size={12} />
            </div>
          </div>
        </div>
        <div className="text-start">
          <p className="flex items-center pt-2 mb-0 font-bold text-lg uppercase">
            {details?.name}
          </p>
          <div className="flex items-center mb-2">
            <CalenderIcon />
            <p className="text-sm mb-0 ms-3">
              <span className="font-bold me-1">Joined On :</span>
              {details?.details?.date}
            </p>
          </div>
          <div className="flex items-center mb-2">
            <BriefCaseIcon fill="#9c9c9c" />
            <p className="text-sm mb-0 ms-3">
              <span className="font-bold me-1">Experience :</span>
              {details?.details?.work_experience} <span>years +</span>
            </p>
          </div>
          <div className="flex items-center mb-2">
            <BuildingIcon fill="#9c9c9c" />
            <p className="text-sm mb-0 ms-3 capitalize">
              <span className="font-bold me-1">Previous/Current Company :</span>
              {details?.details?.previous_company}{" "}
            </p>
          </div>
          <div className="flex items-center mb-2">
            <RupeeIcon fill="#9c9c9c" />
            <p className="text-sm mb-0 ms-3 capitalize">
              <span className="font-bold me-1">Session Charge :</span>₹{" "}
              {details?.details?.fee}{" "}
            </p>
          </div>
          <div className="flex items-center mb-2">
              <LinkedInIcon fill="#9c9c9c" />
              <p className="text-sm mb-0 ms-3">
                <span className="me-1 font-medium">Linkedin :</span>
                {details?.details?.linked_in ? (
                  <a
                    href={details?.details?.linked_in}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-500"
                  >
                    Profile
                  </a>
                ) : (
                  <span className="text-secondary">N/A</span>
                )}
              </p>
            </div>
          <div className="flex mb-4">
            <LocationIcon fill="#9c9c9c" />
            <div className="flex items-center text-sm mb-0 ms-3">
              <span className="font-bold me-1">Address :</span>
              <div className="flex items-center">
                <span className="me-1 capitalize">{details?.city}</span>
                <img
                  src={
                    details?.country_code
                      ? `https://flagsapi.com/${details?.country_code}/flat/64.png`
                      : "https://flagsapi.com/IN/flat/64.png"
                  }
                  alt={`${details?.country} flag`}
                  width={18}
                  title={capitalizeText(details?.country)}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="border-t py-2">
            <p className="mb-0 font-bold">Industries</p>
            {details?.details?.mentor_industry.map((industry, i) => (
              <span key={i} className="outline-tags">
                {industry.replace(new RegExp("_", "g"), " ")}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className="col-span-7 md:col-span-4">
        {details?.deactivate === "1" &&
          details?.deactive_reason?.length > 0 && (
            <div className="hidden md:block bg-slate-200 shadow-md rounded-md py-2 px-3">
              <h5 className="font-semibold pb-2 text-secondary">
                Deactivation Reason :
              </h5>
              <p className="font-medium">{details?.deactive_reason}</p>
            </div>
          )}
        <div className="hidden md:flex items-center justify-between mb-3">
          <div className="flex items-center">
            <Stars rating={details?.details?.average_rating} size={15} />
          </div>
          <div className="flex items-center">
          {details?.is_suspend === "0" ? (
            <Button
              className="px-3 py-1 mx-1 text-xs"
              bgColor="bg-secondary"
              onClick={() => handleConfirmationModal("suspend")}
            >
              Suspend
            </Button>
          ) : (
            <Button
              className="px-3 py-1 mx-1 text-xs"
              bgColor="bg-green-500"
              onClick={() => handleConfirmationModal("unsuspend")}
            >
              Un-Suspend
            </Button>
          )}
          {details?.platform_verify === "0" ? (
              <Button
                className="px-2 py-1 text-xs"
                id="verify-btn"
                onClick={() => handleConfirmationModal("verify")}
              >
                Verify
              </Button>
            ) : (
              <Button className="px-2 py-1 text-xs" id="verified-btn" disabled>
                Verified
              </Button>
            )}
            </div>
        </div>
        <div className="px-0" style={{ height: "270px" }}>
          <div
            className="bg-white shadow-md rounded-md p-3"
            style={{
              height: "100%",
              overflowY: "auto",
            }}
          >
            <h5 className="font-medium pb-3">Startup Idea</h5>
            <p className="mb-0 text-sm">
              {details?.details?.short_description.length > 0
                ? details?.details?.short_description
                : "Not updated."}
            </p>
          </div>
        </div>
        <div className="bg-white shadow-md rounded-md p-3 mt-3">
          <h5 className="text-slate-400 pb-3">Video</h5>
          {details?.video?.length > 0 ? (
            <video
              controls
              className="w-full"
              id="videoFile"
              src={details?.video}
              style={{ height: "200px" }}
            >
              Video uploaded
            </video>
          ) : (
            <p>No video uploaded.</p>
          )}
        </div>
      </div>
    </div>
  );
};


export default MentorDetails;
