import React, { useState, useCallback, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useToastContext } from "../../context/ToastContext";
import useFetch from "../../hooks/useFetch";
import useLocalStorage from "../../hooks/useLocalStorage";
import useUpdateProfile from "../../hooks/useUpdateProfile";
import useDisplayFiles from "../../hooks/useDisplayFiles";
import useUploadImage from "../../hooks/useUploadImage";
import useDisplayIndustries from "../../hooks/useDisplayIndustries";
import { randomId } from "../../utils/randomId";
import { ImportImage } from "../../utils/importImage";
import Navbar from "../../components/layout/Navbar";
import Sidebar from "../../components/layout/Sidebar";
import { Loader } from "../../components/ui/Loader";
import { BankAccountModal } from "../../components/ui/modals/bank/bankAccountModal";
import { DeleteModal } from "../../components/ui/modals/utility-modals/deleteModal";
import { EditMentorProfile } from "../../components/ui/modals/profile/editMentorProfile";
import { EditBankAccountModal } from "../../components/ui/modals/bank/editBankAccountModal";
import { DescriptionModal } from "../../components/ui/modals/profile/DescriptionModal";
import "./Profile.css";
import FavIcon from "../../images/favicon.png";
import banner from "../../images/banner.svg";
import { CameraIcon, DeleteIcon, PenIcon } from "../../icons/icons";
import verifiedLogo from "../../images/verified.png";

const MentorProfile = () => {
  const mentorDetailsApi = `${process.env.REACT_APP_API_URL}display-mentor-profile`;
  const editMentorProfileApi = `${process.env.REACT_APP_API_URL}update-mentor-profile`;
  const deleteBankDetailsApi = `${process.env.REACT_APP_API_URL}delete-bank-details`;
  const uploadVideoApi = `${process.env.REACT_APP_API_URL}upload-video`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token, userRole } = useLocalStorage();
  const { uploadImage } = useUploadImage();
  const { displayFiles } = useDisplayFiles();
  const { updateProfile } = useUpdateProfile();
  const { displayIndustries } = useDisplayIndustries();
  const navigate = useNavigate();
  const ref = useRef(true);
  const toast = useToastContext();

  const [idea, setIdea] = useState("");
  const [video, setVideo] = useState([]);
  const [open, setOpen] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [profileImg, setProfileImg] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openMentorProfile, setOpenMentorProfile] = useState(false);
  const [mentorDetails, setMentorDetails] = useState([]);
  const [editData, setEditData] = useState({});
  const [mentorBank, setMentorBank] = useState([]);
  const [verifiedStartups, setVerifiedStartups] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openEditBank, setOpenEditBank] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [singleBank, setSingleBank] = useState([]);

  const getMentorData = useCallback(() => {
    fetchApi(
      {
        url: mentorDetailsApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
      },
      (data) => {
        setMentorDetails(data.mentor_profile);
        setEditData(...data.mentor_profile);
        setProfileImg(data.mentor_profile[0].logo);
        setIdea(data.mentor_profile[0].short_description);
        setMentorBank(data.bank_details);
        setVerifiedStartups(data.verified_startups);
      }
    );
  }, [fetchApi, token, mentorDetailsApi]);

  const updateMentoDetials = (data, industry) => {
    let dataObj = {
      name: data.mentorName,
      phone: data.mentorMobile,
      work_experience: data.mentorExperience,
      previous_company: data.mentorPastCompany,
      fee: data.mentorFee,
    };
    if (industry.length > 0) {
      dataObj = { ...dataObj, mentor_industry: industry };
    }
    updateProfile(editMentorProfileApi, dataObj, getMentorData, "mentor");
  };

  const addVideo = (newFile) => {
    const formData = new FormData();
    formData.append("video", newFile);
    axios
      .post(uploadVideoApi, formData, {
        headers: { "x-token": token },
      })
      .then(() => {
        toast.open("false", "Video uploaded successfully!");
        displayFiles();
      })
      .catch(() => toast.open("false", "Failed to upload video"));
  };

  const deleteBankDetails = (bankId) => {
    fetchApi(
      {
        url: deleteBankDetailsApi,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: {
          id: bankId,
        },
      },
      () => getMentorData()
    );
  };

  useEffect(() => {
    if (userRole !== "R$7s2") {
      navigate("/error-403");
      return;
    }
    if (ref.current) {
      getMentorData();
      displayFiles((data) => setVideo(data.video));
      displayIndustries((data) => setIndustries(data.industries), "other");
    }
    return () => {
      ref.current = false;
    };
  }, [getMentorData, displayFiles, userRole, navigate, displayIndustries]);

  const handleModal = (type, data) => {
    if (type === "edit") {
      setSingleBank(data);
      setOpenEditBank(true);
    } else {
      setOpen(true);
    }
  };

  const handleDeleteModal = (id) => {
    setSingleBank(id);
    setOpenDeleteModal(true);
  };

  return (
    <div className="grid grid-cols-5">
      <Sidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <Navbar handleSidebar={() => setOpenSidebar(true)} />
        <section className="p-3 md:p-4">
          {isLoading && !error ? (
            <Loader />
          ) : (
            <div>
              <div
                className="relative hidden md:block bg-cover h-[250px]"
                style={{
                  backgroundImage: `url(${banner})`,
                }}
              ></div>
              <div className="px-2 md:px-5 md:shadow-md md:rounded-md bg-white">
                <div className="grid grid-cols-6">
                  <div className="col-span-6 ml-auto md:hidden">
                    <button
                      type="button"
                      className="profile-btns bg-primary text-white mt-2 px-2 py-1 mx-1 rounded-md  text-center text-xs"
                      onClick={() => setOpenMentorProfile(true)}
                    >
                      Edit
                    </button>
                  </div>
                  <div className="col-span-6 md:col-span-1 relative flex items-end justify-center">
                    <img
                      src={profileImg.length > 0 ? profileImg : FavIcon}
                      className="profile-img shadow w-[90px] h-[90px] md:w-[140px] md:h-[140px]"
                      alt="profile-img"
                    />
                    <div className="img-icon bg-primary rounded-full p-1">
                      <label
                        htmlFor="upload-img"
                        className="mb-0 cursor-pointer"
                      >
                        <CameraIcon fill="#fff" />
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        id="upload-img"
                        onChange={(e) =>
                          uploadImage(e.target.files[0], getMentorData)
                        }
                        hidden
                      />
                    </div>
                    {profileImg.length < 1 && (
                      <p className="text-red-500 font-medium">Upload image</p>
                    )}
                  </div>
                  <div className="col-span-6 md:col-span-5 relative py-2 md:pl-[3.5rem] 2xl:pl-0 mb-5">
                    <button
                      type="button"
                      className="hidden md:block profile-btns bg-primary text-white mt-2 text-center px-2 py-1 rounded-md absolute right-0 md:top-unset md:bottom-2.5 text-xs md:text-sm"
                      onClick={() => setOpenMentorProfile(true)}
                    >
                      Edit
                    </button>
                    {mentorDetails.map((mentor) => (
                      <div key={mentor.user_id}>
                        <div className="grid grid-cols-2 mt-10 gap-y-2 md:mt-0">
                          <p className="col-span-2 md:col-span-1 flex items-center font-bold">
                            Name :
                            <span className="flex items-center ms-1 uppercase font-medium">
                              {mentor.name}
                            </span>
                            {mentor.platform_verify == 1 && (
                                <img
                                src={verifiedLogo}
                                alt="Platform Verified Logo"
                                title="Platform Verified"
                                width={25}
                                />
                            )}
                          </p>
                          <p className="col-span-2 md:col-span-1 flex items-center font-bold">
                            Email :
                            <span className="ms-1 font-medium">
                              {mentor.email}
                            </span>
                          </p>
                          <p className="col-span-2 md:col-span-1 font-bold">
                            Experience :
                            <span className="ms-1 font-medium">
                              {mentor.work_experience}+ Years
                            </span>
                          </p>
                          <p className="col-span-2 md:col-span-1 font-bold">
                            Company :
                            <span className="ms-1 capitalize font-medium">
                              {mentor.previous_company}
                            </span>
                          </p>
                          <p className="col-span-2 font-bold">
                            Industries worked In :
                            <span className="font-medium ms-1">
                              {mentor.mentor_industry.map((industry, i) => (
                                <span className="outline-tags" key={i}>
                                  {industry.replace(new RegExp("_", "g"), " ")}
                                </span>
                              ))}
                            </span>
                          </p>
                          <p className="font-bold">
                            Session Charges:
                            <span className="ms-1 capitalize font-medium">
                              ₹ {mentor.fee}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3 mx-0 px-0">
                <div className="ideaContainer col-span-2 md:col-span-1 mt-3 bg-white shadow-md rounded-md p-3 mb-2 relative">
                  <div className="flex items-center justify-between">
                    <h5 className="font-semibold">About Me</h5>
                    <p
                      className="outline-btn text-xs md:text-sm"
                      type="button"
                      onClick={() => setDescriptionModal(true)}
                    >
                      Write here
                    </p>
                  </div>
                  {idea.length > 0 ? (
                    <p className="mt-5">{idea}</p>
                  ) : (
                    <p className="mt-5">
                      Add short description about your company and work
                    </p>
                  )}
                </div>
                <div className="documentsContainer col-span-2 md:col-span-1 bg-white shadow-md rounded-md mb-2 mt-3 p-3 relative">
                  <div className="flex items-center justify-between">
                    <h5 className="font-semibold">Video About Me</h5>
                    <label
                      className="outline-btn text-xs md:text-sm"
                      htmlFor="mentor-video"
                    >
                      Upload video
                    </label>
                    <input
                      type="file"
                      id="mentor-video"
                      accept="video/mp4,video/x-m4v,video/*"
                      hidden
                      onChange={(e) => addVideo(e.target.files[0])}
                    />
                  </div>
                  {video.length > 0 ? (
                    <div className="grid grid-cols-1 mt-3">
                      <div className="mt-4">
                        <video
                          controls
                          className="w-full h-[300px]"
                          id="videoFile"
                          src={video}
                        ></video>
                      </div>
                    </div>
                  ) : (
                    <p>No Video found.</p>
                  )}
                </div>
                <div className="bankDetailsContainer col-span-2 md:col-span-1 bg-white shadow-md rounded-md p-3 relative">
                  <div className="flex items-center justify-between mb-3">
                    <h5 className="font-semibold">Bank Details</h5>
                    {mentorBank.length < 1 && (
                      <p
                        className="outline-btn text-xs md:text-sm"
                        onClick={() => handleModal("add")}
                        type="button"
                      >
                        Add new
                      </p>
                    )}
                  </div>
                  <div className="grid grid-cols-1 mt-3" id="bank-details">
                    {mentorBank.length > 0 ? (
                      mentorBank.map((bank) => (
                        <div
                          className="profile-detail-card relative"
                          key={bank.id}
                        >
                          <div className="flex justify-between items-center px-4 py-2 mt-2 rounded-md bg-gray-200 hover:opacity-50">
                            <div>
                              <p className="font-semibold text-xs md:text-sm leading-relaxed">
                                Account Holder:
                                <span className="ps-2 font-medium whitespace-normal">
                                  {bank.name}
                                </span>
                              </p>
                              <p className="font-semibold text-xs md:text-sm leading-relaxed">
                                Account Number:
                                <span className="ps-2 font-medium whitespace-normal">
                                  {bank.account_number}
                                </span>
                              </p>
                              <p className="font-semibold text-xs md:text-sm leading-relaxed">
                                IFSC Code:
                                <span className="ps-2 font-medium whitespace-normal">
                                  {bank.ifsc}
                                </span>
                              </p>
                            </div>
                            <ImportImage
                              imageName={bank.bank_name}
                              width={45}
                              alt={bank.bank_name}
                            />
                          </div>
                          <DeleteIcon
                            className="delete-account-btn absolute right-0 top-0 cursor-pointer"
                            onClick={() => handleDeleteModal(bank.id)}
                          />
                          <PenIcon
                            fill="#22C55E"
                            className="edit-account-details absolute right-[10%] top-0 cursor-pointer"
                            onClick={() => handleModal("edit", bank)}
                          />
                        </div>
                      ))
                    ) : (
                      <p id="noBankDetails">No Bank Account found.</p>
                    )}
                  </div>
                </div>
                <div className="verifiedStartupsContainer col-span-2 md:col-span-1 bg-white shadow-md rounded-md p-3 relative">
                  <h5 className="font-semibold">Startups Verified By You</h5>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                    {verifiedStartups.length > 0 ? (
                      verifiedStartups.map((review) => (
                        <Link
                          key={review.startup_id}
                          to={`/view-startup/${review.name
                            .split(" ")
                            .join("-")}/${review.startup_id}`}
                          className="flex justify-between items-center p-2 bg-gray-200 rounded-md"
                        >
                          <div className="flex items-center">
                            <img
                              src={
                                review.logo.length > 0 ? review.logo : FavIcon
                              }
                              alt="company-logo"
                              className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] rounded-full"
                            />
                            <p className="ms-2 font-medium">
                              {review.name.length > 15
                                ? review.name.slice(0, 15).concat("...")
                                : review.name}
                            </p>
                          </div>
                        </Link>
                      ))
                    ) : (
                      <p className="md:col-span-2 mt-3">
                        You have not verified any startups yet.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
        <BankAccountModal
          open={open}
          onClose={() => setOpen(false)}
          callBackFn={getMentorData}
        />
        <EditBankAccountModal
          key={singleBank.id}
          open={openEditBank}
          onClose={() => setOpenEditBank(false)}
          singleBank={singleBank}
          bankId={singleBank.id}
          callBackFn={getMentorData}
        />
        <DeleteModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          deleteBankDetails={deleteBankDetails}
          id={singleBank.id}
          type="bank"
        />
        <EditMentorProfile
          key={randomId()}
          open={openMentorProfile}
          onClose={() => setOpenMentorProfile(false)}
          updateMentoDetials={updateMentoDetials}
          editData={editData}
          industries={industries}
        />
        <DescriptionModal
          key={new Date()}
          open={descriptionModal}
          onClose={() => setDescriptionModal(false)}
          callBackFn={() => getMentorData()}
          url={editMentorProfileApi}
          data={idea}
        />
      </div>
    </div>
  );
};

export default MentorProfile;
