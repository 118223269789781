import { useEffect, useState } from "react";
import ReactDom from "react-dom";
import { useForm } from "react-hook-form";
import { FormError } from "../../FormError";
import { Button } from "../../Button";

export const MeetingSchedule = ({
  open,
  onClose,
  data = [],
  scheduleMeeting,
  userData = {},
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({
    defaultValues: {
      user: userData?.name,
    },
  });
  const [selectedUser, setSelectedUser] = useState({ fee: 0 });

  const today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  if (!open) return null;

  const submitModal = (formData) => {
    console.log('form date ' + formData.date);
    const formattedDate = formatDate(formData.date);
    const updatedData = { ...formData, date: formattedDate };
    console.log(updatedData);
    if (Object.keys(userData).length > 0) {
      scheduleMeeting("chat", updatedData, userData.userId);
    } else {
      scheduleMeeting("other", updatedData);
    }
    onClose();
  };

  const formatDate = (date) => {
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  };

  const handleUserChange = (event) => {
    const selectedUserId = event.target.value;
    const selectedUserData = data.find(
      (user) => user.connection_id === selectedUserId
    );
    if (selectedUserData) {
      setSelectedUser(selectedUserData);
    }
  };

  return ReactDom.createPortal(
    <div
      className={`fixed inset-0 flex justify-center items-center transition-colors z-10 ${
        open ? "visible bg-black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`w-[90%] md:w-[40%] bg-white rounded-xl shadow p-4 pb-2 transition-all ${
          open ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <p onClick={onClose} className="closeBtn">
          X
        </p>
        <h4 className="py-2 border-b text-center font-semibold text-base">
          Schedule New Meeting
        </h4>
        <div className="p-3 pb-0 mt-2 text-center">
          {data.length || Object.keys(userData).length > 0 ? (
            <form
              onSubmit={handleSubmit(submitModal)}
              className="grid grid-cols-2 gap-2"
            >
              <p className="col-span-2 text-start font-medium text-sm mb-2">
                Note: All meetings are capped at 1 hour.
              </p>
              {data.length > 0 ? (
                <div className="flex flex-col items-start justify-center">
                  <label htmlFor="user" className="font-medium required">
                    User
                  </label>
                  <select
                    id="user"
                    name="user"
                    className="border border-slate-400 rounded-md p-2 w-full"
                    {...register("user", {
                      required: "Please select a user",
                    })}
                    onChange={handleUserChange}
                  >
                    <option value="">Select User</option>
                    {data
                      ?.filter(
                        (user) => user.status === "1" && user.role !== "V&iR8"
                      )
                      .map((user) => (
                        <option
                          value={user.connection_id}
                          key={user.connection_id}
                        >
                          {user.name} -
                          {user.role === "f@!3A"
                            ? "startup"
                            : user.role === "R$7s2"
                            ? "mentor"
                            : "investor"}
                        </option>
                      ))}
                  </select>
                  {errors.user && (
                    <div>
                      <FormError message={errors.user.message} />
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex flex-col items-start justify-center">
                  <label htmlFor="user" className="font-medium">
                    user
                  </label>
                  <input
                    type="text"
                    id="user"
                    name="user"
                    className="border border-slate-400 p-1.5 rounded-md w-full"
                    {...register("user", {
                      required: "Please enter a user",
                    })}
                    disabled
                  />
                  {errors.user && (
                    <div>
                      <FormError message={errors.user.message} />
                    </div>
                  )}
                </div>
              )}
              <div className="flex flex-col items-start justify-center">
                <label htmlFor="mentorFee" className="font-medium">
                  Fees ( tokens )
                </label>
                <input
                  type="text"
                  id="mentorFee"
                  name="mentorFee"
                  className="border border-slate-400 p-1.5 rounded-md w-full disabled"
                  {...register("mentorFee")}
                  value={selectedUser?.fee}
                  disabled
                />
                {errors.mentorFee && (
                  <div>
                    <FormError message={errors.mentorFee.message} />
                  </div>
                )}
              </div>
              <div className="flex flex-col items-start justify-center">
                <label htmlFor="title" className="font-medium required">
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  className="border border-slate-400 p-1.5 rounded-md w-full"
                  placeholder="Enter Meeting Title..."
                  {...register("title", {
                    required: "Please enter a title",
                  })}
                />
                {errors.title && (
                  <div>
                    <FormError message={errors.title.message} />
                  </div>
                )}
              </div>
              <div className="flex flex-col items-start justify-center">
                <label htmlFor="date" className="font-medium required">
                  date
                </label>
                <input
                  type="date"
                  name="date"
                  id="date"
                  min={today}
                  className="border border-slate-400 p-1.5 rounded-md w-full"
                  {...register("date", { required: "Please select date" })}
                />
                {errors.date && (
                  <div>
                    <FormError message={errors.date.message} />
                  </div>
                )}
              </div>
              <p className="col-span-2 text-start font-medium border-t pt-1">
                Meeting Start Time
              </p>
              <div className="flex flex-col items-start justify-center">
                <label htmlFor="hour" className="font-medium required">
                  Hour
                </label>
                <select
                  id="hour"
                  name="hour"
                  className="border border-slate-400 rounded-md p-2 w-full"
                  {...register("hour", { required: "Please select a time" })}
                >
                  <option value="00">00</option>
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                </select>
                {errors.hour && (
                  <div>
                    <FormError message={errors.hour.message} />
                  </div>
                )}
              </div>
              <div className="flex flex-col items-start justify-center">
                <label htmlFor="minutes" className="font-medium required">
                  Minutes
                </label>
                <select
                  id="minutes"
                  name="minutes"
                  className="border border-slate-400 rounded-md p-2 w-full"
                  {...register("minutes", { required: "Please select a time" })}
                >
                  <option value="00">00</option>
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="45">45</option>
                  <option value="59">59</option>
                </select>
                {errors.minutes && (
                  <div>
                    <FormError message={errors.minutes.message} />
                  </div>
                )}
              </div>
              <div className="col-start-2 text-end mt-auto">
                <Button
                  type="submit"
                  className="px-2 py-1"
                  bgColor="bg-primary"
                >
                  Schedule
                </Button>
              </div>
            </form>
          ) : (
            <p>
              You don't have any connections yet. Connect with a user to
              schedule a call.
            </p>
          )}
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
