import React, { useCallback, useEffect, useMemo, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { calculateMonthsPassed } from "../../../utils/calculateMonthsPassed";
import AdminSidebar from "../../../components/layout/adminSidebar";
import AdminNavbar from "../../../components/layout/adminNavbar";
import SmallCardWithoutButton from "../../../components/ui/cards/smallCardWithoutButton";
import { Error } from "../../../components/ui/Error";
import { Loader } from "../../../components/ui/Loader";
import LineChart from "../../../components/ui/graphs-charts/lineChart";
import { BarGraph } from "../../../components/ui/graphs-charts/barGraph";
import RevenueCard from "../../../components/ui/cards/revenueCard";
import "./adminDashboard.css";
import { CircleIcon } from "../../../icons/icons";

const AdminDashboard = () => {
  const revenueDetailsApi = `${process.env.REACT_APP_API_URL}revenue-details`;
  const getUsersDataApi = `${process.env.REACT_APP_API_URL}users-data`;

  const { isLoading, error, fetchApi } = useFetch();
  const { token } = useLocalStorage();
  const memoizedMonthsPassed = useMemo(() => calculateMonthsPassed(), []);

  const [openSidebar, setOpenSidebar] = useState(false);
  const [data, setData] = useState({
    total: 0,
    startup: 0,
    mentor: 0,
    investor: 0,
    vendor: 0,
  });

  const [chart, setChart] = useState({
    total: [],
    market: [],
    subscription: [],
    connection: [],
    cities: [],
    industries: [],
    totalUsers: [],
    startups: [],
    mentors: [],
    investors: [],
    vendors: [],
  });
  const [revenueData, setRevenueData] = useState({
    total: 0,
    market: 0,
    subscription: 0,
    connection: 0,
    investment: 0,
    investment_revenue: 0,
    investment_ask: 0,
    investment_capability: 0,
  });
  const [type, setType] = useState("total");
  const [registrationGraph, setRegistrationGraph] = useState("totalUsers");

  const getRevenueData = useCallback(() => {
    fetchApi(
      {
        url: revenueDetailsApi,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
      },
      (data) => {
        setChart((prevChart) => ({
          ...prevChart,
          total: [
            {
              id: "total",
              data: data.total_revenue,
            },
          ],
          market: [
            {
              id: "market",
              data: data.market_data,
            },
          ],
          subscription: [
            {
              id: "subscription",
              data: data.subscription_data,
            },
          ],
          connection: [
            {
              id: "connection",
              data: data.connection_data,
            },
          ],
        }));
        setRevenueData({
          total:
            data.connection_revenue +
            data.market_revenue +
            data.subscription_revenue,
          market: data.market_revenue,
          subscription: data.subscription_revenue,
          connection: data.connection_revenue,
          investment: data.total_investment,
          investment_revenue: data.investment_revenue,
          investment_ask: data.require_investment,
          investment_capability: data.future_investment,
        });
      }
    );
  }, [revenueDetailsApi, fetchApi, token]);

  const getUsersData = useCallback(() => {
    fetchApi(
      {
        url: getUsersDataApi,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-token": token,
        },
      },
      (data) => {
        setData({
          total: data.total_users,
          startup: data.startup_count,
          mentor: data.mentor_count,
          investor: data.investor_count,
          vendor: data.vendor_count,
        });
        setChart((prevChart) => ({
          ...prevChart,
          totalUsers: [
            {
              id: "registrations",
              data: data.user_data,
            },
          ],
          startups: [
            {
              id: "startups",
              data: data.startup_data,
            },
          ],
          mentors: [
            {
              id: "mentors",
              data: data.mentor_data,
            },
          ],
          investors: [
            {
              id: "investors",
              data: data.investor_data,
            },
          ],
          vendors: [
            {
              id: "vendors",
              data: data.vendor_data,
            },
          ],
          cities: [...data.cities_data],
          industries: [...data.industries_data],
        }));
      }
    );
  }, [getUsersDataApi, fetchApi, token]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getRevenueData();
      getUsersData();
    }, 20);
    return () => {
      clearTimeout(timer);
    };
  }, [getRevenueData, getUsersData]);

  return (
    <div className="grid grid-cols-5">
      <AdminSidebar
        openSidebar={openSidebar}
        closeSidebar={() => setOpenSidebar(false)}
      />
      <div className="right-half col-span-5 lg:col-span-4">
        <AdminNavbar handleSidebar={() => setOpenSidebar(true)} />
        <div className="p-4">
          {isLoading && <Loader />}
          {error && <Error />}
          {!isLoading && !error && (
            <div className="grid grid-cols-4 gap-2">
              <h4 className="col-span-4 font-medium text-lg">Users</h4>
              <div className="col-span-4 md:col-span-2">
                <div className="grid grid-cols-2 gap-3">
                  <div className="col-span-2 md:col-span-1 shadow-md rounded-md">
                    <SmallCardWithoutButton
                      data={{
                        title: "Total",
                        count: data.total,
                      }}
                      icon="total"
                      gradient="blue-icon"
                      font="medium"
                      titleColor="text-[#9c9c9c]"
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1 shadow-md rounded-md">
                    <SmallCardWithoutButton
                      data={{
                        title: "Startups",
                        count: data.startup,
                      }}
                      icon="startup"
                      gradient="green-icon"
                      font="medium"
                      titleColor="text-[#9c9c9c]"
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1 shadow-md rounded-md">
                    <SmallCardWithoutButton
                      data={{
                        title: "Mentors",
                        count: data.mentor,
                      }}
                      icon="mentor"
                      gradient="orange-icon"
                      font="medium"
                      titleColor="text-[#9c9c9c]"
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1 shadow-md rounded-md">
                    <SmallCardWithoutButton
                      data={{
                        title: "Investors",
                        count: data.investor,
                      }}
                      icon="investor"
                      gradient="pink-icon"
                      font="medium"
                      titleColor="text-[#9c9c9c]"
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1 shadow-md rounded-md">
                    <SmallCardWithoutButton
                      data={{
                        title: "Service Provider",
                        count: data.vendor,
                      }}
                      icon="vendor"
                      gradient="blue-icon"
                      font="medium"
                      titleColor="text-[#9c9c9c]"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-4 md:col-span-2">
                <div className="p-2 bg-white shadow-md rounded-md">
                  <div className="flex items-center justify-around flex-wrap gap-2.5">
                    <p
                      className="flex items-center cursor-pointer"
                      onClick={() => setRegistrationGraph("totalUsers")}
                    >
                      <CircleIcon fill="#17335E" className="pe-1" />
                      <span>Total</span>
                    </p>
                    <p
                      className="flex items-center cursor-pointer"
                      onClick={() => setRegistrationGraph("startups")}
                    >
                      <CircleIcon fill="#B02444" className="pe-1" />
                      <span>Startups</span>
                    </p>
                    <p
                      className="flex items-center cursor-pointer"
                      onClick={() => setRegistrationGraph("mentors")}
                    >
                      <CircleIcon fill="#16A34A" className="pe-1" />
                      <span>Mentors</span>
                    </p>
                    <p
                      className="flex items-center cursor-pointer"
                      onClick={() => setRegistrationGraph("investors")}
                    >
                      <CircleIcon fill="#F97316" className="pe-1" />
                      <span>Investors</span>
                    </p>
                    <p
                      className="flex items-center cursor-pointer"
                      onClick={() => setRegistrationGraph("vendors")}
                    >
                      <CircleIcon fill="#627BFA" className="pe-1" />
                      <span>Service</span>
                    </p>
                  </div>
                  <LineChart
                    data={{
                      totalUsers: chart.totalUsers,
                      startups: chart.startups,
                      mentors: chart.mentors,
                      investors: chart.investors,
                      vendors: chart.vendors,
                    }}
                    mainType="user"
                    subType={registrationGraph}
                  />
                </div>
              </div>
              <div className="col-span-4 md:col-span-2 card shadow-md rounded-md">
                <h5 className="text-lg font-medium">
                  Popular industries
                </h5>
                {chart.industries.length > 0 && (
                  <div className="px-0 cstm-svg-pos uppercase">
                    <BarGraph data={chart.industries} />
                  </div>
                )}
              </div>
              <div className="col-span-4 md:col-span-2 card shadow-md rounded-md">
                <h5 className="text-lg font-medium">Popular cities</h5>
                {chart.cities.length > 0 && (
                  <div className="px-0 cstm-svg-pos uppercase">
                    <BarGraph data={chart.cities} type="cities" />
                  </div>
                )}
              </div>
              <h4 className="col-span-4 mt-5 font-medium text-lg">Revenue</h4>
              <div className="col-span-4 md:col-span-2">
                <div className="p-2 bg-white shadow-md rounded-md">
                  <div className="flex items-center justify-around">
                    <p
                      className="flex items-center cursor-pointer"
                      onClick={() => setType("total")}
                    >
                      <CircleIcon fill="#17335E" className="pe-1" />
                      <span>Total</span>
                    </p>
                    <p
                      className="flex items-center cursor-pointer"
                      onClick={() => setType("subscription")}
                    >
                      <CircleIcon fill="#B02444" className="pe-1" />
                      <span>Subscription</span>
                    </p>
                    <p
                      className="flex items-center cursor-pointer"
                      onClick={() => setType("market")}
                    >
                      <CircleIcon fill="#16A34A" className="pe-1" />
                      <span>Market</span>
                    </p>
                    <p
                      className="flex items-center cursor-pointer"
                      onClick={() => setType("connection")}
                    >
                      <CircleIcon fill="#F97316" className="pe-1" />
                      <span>Connection</span>
                    </p>
                  </div>
                  <LineChart
                    data={{
                      total: chart.total,
                      market: chart.market,
                      subscription: chart.subscription,
                      connection: chart.connection,
                    }}
                    mainType="revenue"
                    subType={type}
                    height="h-[400px]"
                  />
                </div>
              </div>
              <div className="col-span-4 md:col-span-2">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <RevenueCard
                    title="Total Revenue"
                    amount={revenueData.total}
                    classes="mx-auto sm:m-0"
                  />
                  <RevenueCard
                    title="Monthly Revenue"
                    amount={revenueData.total / memoizedMonthsPassed}
                    bgColor="bg-secondary"
                    borderColor="border-secondary"
                    classes="mx-auto sm:m-0"
                  />
                  <RevenueCard
                    title="Subscription Revenue"
                    amount={revenueData.subscription}
                    bgColor="bg-[#f97316]"
                    borderColor="border-[#f97316]"
                    classes="mx-auto sm:m-0"
                  />
                  <RevenueCard
                    title="Market Revenue"
                    amount={revenueData.market}
                    bgColor="bg-green-600"
                    borderColor="border-green-600"
                    classes="mx-auto sm:m-0"
                  />
                  <RevenueCard
                    title="Connection Revenue"
                    amount={revenueData.connection}
                    bgColor="bg-blue-600"
                    borderColor="border-blue-600"
                    classes="mx-auto sm:m-0"
                  />
                  <RevenueCard
                    title="Investment Raised"
                    amount={revenueData.investment}
                    classes="mx-auto sm:m-0"
                  />
                  <RevenueCard
                    title="Investment Revenue"
                    amount={revenueData.investment_revenue}
                    bgColor="bg-secondary"
                    borderColor="border-secondary"
                    classes="mx-auto sm:m-0"
                  />
                  <RevenueCard
                    title="Investment Ask"
                    amount={revenueData.investment_ask}
                    bgColor="bg-[#f97316]"
                    borderColor="border-[#f97316]"
                    classes="mx-auto sm:m-0"
                  />
                  <RevenueCard
                    title="Investment Capability"
                    amount={revenueData.investment_capability}
                    bgColor="bg-green-600"
                    borderColor="border-green-600"
                    classes="mx-auto sm:m-0"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
